/*
 * @Author       : ZM
 * @Date         : 2021-12-28 10:13:41
 * @LastEditors  : ZM
 * @LastEditTime : 2022-02-14 16:34:08
 * @Description  : is me
 */
const formatDateTime = (date: any) => {
    if ("Invalid Date" == date) {
        return;
    }
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    let d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    let h = date.getHours();
    h = h < 10 ? ('0' + h) : h;
    let minute = date.getMinutes();
    minute = minute < 10 ? ('0' + minute) : minute;
    let second = date.getSeconds();
    second = second < 10 ? ('0' + second) : second;

    return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
}

const handleTime = (time: any, format: any) => {
    if (time == null || time == undefined || time == "") {
        return "";
    }
    let t = new Date(time);
    let tf = function (i: any) {
        return (i < 10 ? '0' : '') + i;
    };
    return format.replace(/yyyy|MM|dd|HH|mm|ss/g, function (a: any) {
        // console.log('format1');
        switch (a) {
            case 'yyyy':
                return tf(t.getFullYear());
                break;
            case 'MM':
                return tf(t.getMonth() + 1);
                break;
            case 'mm':
                return tf(t.getMinutes());
                break;
            case 'dd':
                return tf(t.getDate());
                break;
            case 'HH':
                return tf(t.getHours());
                break;
            case 'ss':
                return tf(t.getSeconds());
                break;
        }
    })
}

/**
 * str转Date
 * @param dates 
 * @returns 
 */
const strToDate = (dates: any) => {
    // if (dates instanceof Date) {
    //     return dates;
    // }
    return new Date(dates);
}
/**
 * 给时间增加年
 * @param date 
 * @param fullYear 
 * @returns 
 */
const addFullYear = (date: Date, fullYear: number) => {
    if (date == null) {
        return;
    }

    date.setFullYear(date.getFullYear() + fullYear);
    return date;
}

/**
 * 给时间增加月
 * @param date 
 * @param month 
 * @returns 
 */
const addMonth = (date: Date, month: number) => {
    if (date == null) {
        return;
    }

    date.setMonth(date.getMonth() + month);
    return date;
}
/**
 * 给时间增加天
 * @param date 
 * @param day 
 * @returns 
 */
const addDay = (date: Date, day: number) => {
    if (date == null) {
        return;
    }

    date.setDate(date.getDate() + day);
    return date;
}

/**
 * 给时间增加小时
 * @param date 
 * @param day 
 * @returns 
 */
const addHours = (date: Date, hours: number) => {
    if (date == null) {
        return;
    }

    date.setHours(date.getHours() + hours);
    return date;
}


/**
 * 给时间增加分钟
 * @param date 
 * @param day 
 * @returns 
 */
const addMinutes = (date: Date, minutes: number) => {
    if (date == null) {
        return;
    }

    date.setMinutes(date.getMinutes() + minutes);
    return date;
}

/**
 * 比较 date1 是否比date2大 
 * @param date1 
 * @param date2 
 * @returns 
 */
const compareDate = (date1: any, date2: any) => {
    if (date1 == null || date2 == null) {
        return;
    }
    if (date1 == '' || date2 == '') {
        return;
    }
    if (!(date1 instanceof Date)) {
        date1 = strToDate(date1);
    }
    if (!(date2 instanceof Date)) {
        date2 = strToDate(date2);
    }
    if (date1 > date2) {
        return true;
    } else {
        return false;
    }
}

/**
 * 两个时间减，以大减小
 * @param date1 
 * @param date2 
 * @returns 
 */
const dateDifferenceDay = (date1: any, date2: any) => {
    if (date1 == null || date2 == null) {
        return -1;
    }
    if (date1 == '' || date2 == '') {
        return -1;
    }
    if (!(date1 instanceof Date)) {
        date1 = strToDate(date1);
    }
    if (!(date2 instanceof Date)) {
        date2 = strToDate(date2);
    }

    if (compareDate(date1, date2)) {
        let n: any = (date1 - date2) / (1000 * 60 * 60 * 24);
        return parseInt(n);
    } else {
        let n: any = (date2 - date1) / (1000 * 60 * 60 * 24);
        return parseInt(n);
    }
}

export default {
    formatDateTime,
    handleTime,
    strToDate,
    addFullYear,
    addMonth,
    addDay,
    compareDate,
    dateDifferenceDay
}

