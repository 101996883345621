
import { defineComponent, reactive, toRefs, ref, onMounted } from 'vue'
import { ElMessage } from 'element-plus'
import {
  getAppVersionList,
  editAppVersion,
  addAppVersion,
  isEditAuthByCode
} from '@/api/index'
import formatDate from './../util/formatDate';
interface deviceDataF {
  loading: boolean
  rules: any
  pageSize: number
  pageSizes: Array<number>
  currentPage: number
  total: number
  rowsData: Array<any>
  editFlag: boolean
  editForm: any
    searchDataAny: any //搜索条件
    isDebugOption: any
    typeOption: any
  isForceOption:any
}

export default defineComponent({
  name: 'appVersionTable',
  components: {},
  setup() {
      const objRef = ref()

    const deviceData: deviceDataF = reactive({
      loading: true,
      editFlag: false,
      editForm: {},
      searchDataAny: {}, //搜索条件
      rowsData: [],
      total: 0,
      pageSize: 10,
      pageSizes: [10, 30, 50, 100],
      currentPage: 1,
      rules: {
        version: [{ required: true, message: '请输入 版本信息', trigger: 'blur' }],
        isDebug: [{ required: true, message: '请输入 调试开关', trigger: 'blur' }],
        isForce: [{ required: true, message: '请输入 强制更新', trigger: 'blur' }],
        versionCode: [{ required: true, message: '请输入 版本号', trigger: 'blur' }],
        },
        isDebugOption: [
        {
          value: 0,
          label: '正式',
        },
        {
          value: 1,
          label: '调试',
        }
      ],
        isForceOption: [
        {
          value: 0,
          label: '否',
        },
        {
          value: 1,
          label: '是',
        }
      ],
        typeOption: [
        {
          value: 0,
          label: '安卓',
        },
        {
          value: 1,
          label: 'IOS',
        }
      ]
    })

    const data = toRefs(deviceData)
    onMounted(() => {
          setTimeout(function(){
              getList()
          },50);
    })


    //编辑
    const editorFun = (e: any) => {
      let obj: any = {}
      Object.assign(obj, e)
      deviceData.editForm = obj
      deviceData.editForm.isEdit = true      
      deviceData.editFlag = true
      }
      
      const addFun = () => {
          deviceData.editForm = { isEdit: false };
      deviceData.editFlag = true
    }
    
    const editSave = async () => {
      objRef.value.validate(async (valid: any) => {
         if (valid) {
         deviceData.loading = true
          let array: any = {}
          Object.assign(array, deviceData.editForm)
          let fun = addAppVersion
          if (deviceData.editForm.isEdit) {
            fun = editAppVersion
          }
          fun(array).then((res: any) => {
            let { code } = res
            if (code == 0) {
              deviceData.editFlag = false
              getList()
            } else {
              ElMessage.error(res.data)
            }
          })
        } else {
          return false
        }
      })
      }
    
    const search = () => {
      deviceData.currentPage = 1
      getList()
    }


    const getList = async () => {
      deviceData.searchDataAny.length = deviceData.pageSize
      deviceData.searchDataAny.page = deviceData.currentPage
      deviceData.loading = true
      await getAppVersionList(deviceData.searchDataAny).then(
        (res: any) => {
          if (res.code == 0) {
              if(res.data!=null && res.data.list!=null){
                  deviceData.rowsData = res.data.list
                  deviceData.total = res.data.total
              }else{
                  deviceData.rowsData = [];
                  deviceData.total = 0;
                  ElMessage.success('加载成功，数据为空！');
              }
            
          setTimeout(function(){
              deviceData.loading = false
          },100);
          
          }else if(res.errMsg!=null){
            ElMessage.error(res.errMsg);
              deviceData.loading = false
        }else{
            ElMessage.error("加载失败");
              deviceData.loading = false
        }
        }
      )
    }

    const handleSizeChange = (val: any) => {
      //设置当前页显示几条数据
      deviceData.pageSize = val
      getList()
    }
    const handleCurrentChange = (val: any) => {
      //当前页码
      deviceData.currentPage = val
      getList()
    }


    return {
      ...data,
      addFun,
      objRef,
      editSave,
        search,
      editorFun,
      handleSizeChange,
      handleCurrentChange,
    isEditAuthByCode,formatDate
    }
  },
})
